import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { CloseButton } from '@/assets/icons';
import { DetailCourseCard } from './DetailCourseCard';
import CourseInformationCard from './CourseInformationCard';
import { RegisterCourse } from './RegisterCourse';
import { useRouter } from 'next/router';

interface DetailAuthorModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
const DetailCourseModal: React.FC<DetailAuthorModalProps> = ({
  setShowModal,
}) => {
  const router = useRouter();
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowModal(false);
        router.replace(
          { pathname: router.pathname, query: { ...router.query } },
          undefined,
          {
            scroll: false,
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );

  const handleCloseModal = () => {
    setShowModal(false);
    router.replace(
      { pathname: router.pathname, query: { ...router.query } },
      undefined,
      {
        scroll: false,
      },
    );
  };

  return (
    <div
      onClick={handleClickOutside}
      className="fixed inset-0 z-50 flex justify-center bg-[#00000099] backdrop-blur-sm"
    >
      <div
        ref={modalRef}
        className="mx-2 my-[20px] flex h-[96vh] w-full flex-col gap-4 bg-white pb-4 md:inset-x-auto md:top-[50px] md:mx-0 lg:w-auto"
      >
        <div className="flex flex-col">
          <div className="bg-neutral-grayDark flex cursor-pointer flex-row items-center justify-end px-4 py-1">
            <div
              className="flex flex-row items-center justify-end"
              onClick={() => handleCloseModal()}
            >
              <CloseButton className="cursor-pointer" />
              <span className="text-PC-Title-Regular font-sans text-[#DCDEE0]">
                ĐÓNG
              </span>
            </div>
          </div>
          <div className="flex h-[88vh] justify-center overflow-y-scroll py-4 pl-4 pr-2 md:p-6">
            <div className="flex flex-col md:gap-4 lg:gap-2">
              <div className="mt-2 flex flex-col justify-between gap-4 px-4 lg:container md:flex-row lg:px-0">
                <div className="flex flex-col">
                  <DetailCourseCard />
                </div>
                <div className="my-4 flex flex-col gap-2 md:my-0 md:flex md:max-w-[320px]">
                  <CourseInformationCard setShowModal={setShowModal} />
                  <RegisterCourse />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailCourseModal;
