import React, { Dispatch, SetStateAction } from 'react';
import LazyImage from '../shared/LazyImage';
import { ICourseKnowledge } from '@/interfaces/Course';
import { UrlLink } from '@/configs/urlLink';
import { useRouter } from 'next/router';
import { format, parseISO } from 'date-fns';
import { useGetAllLecturerQuery } from '@/generated/graphql';
import Link from 'next/link';

export interface CardPlaylistProps {
  data?: ICourseKnowledge;
  isLoading: boolean;
  isShowLecturer?: boolean;
  onClick?: () => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const CardPlaylistSkeleton = (
  <div className="bg-gray-variants-2 flex h-full w-56 animate-pulse flex-col gap-4 rounded-2xl p-4">
    <div className="bg-gray-variants-8 h-48" />
    <div className="bg-gray-variants-8 h-6 w-1/2 rounded-lg" />
    <div className="bg-gray-variants-8 h-6 w-full rounded-lg" />
  </div>
);

const CourseCard = ({
  data,
  isLoading,
  setShowModal,
  isShowLecturer,
}: CardPlaylistProps) => {
  const { data: listAllLecturers } = useGetAllLecturerQuery();
  const courseStatusEnd = new Date(data?.start_date) < new Date();
  const router = useRouter();
  const detailLecture = listAllLecturers?.lecturer.find(
    lecturer => lecturer.id === data?.lecturer_id,
  );
  return (
    <>
      {isLoading ? (
        CardPlaylistSkeleton
      ) : (
        <div
          className={`flex h-full flex-col rounded-[4px] border-[1px] border-[#DCDEE0]`}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="bg-primary relative aspect-video w-full">
              <LazyImage
                src={data?.image}
                alt="Thumbnail course"
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
                className="object-fill"
                style={{ borderRadius: '4px' }}
              />
              <div
                className={` text-PC-Caption-Regular rounded-[2px] px-2 py-1 ${
                  courseStatusEnd
                    ? 'bg-neutral-grayLight text-neutral-grayDark'
                    : 'bg-primary-eastern text-white'
                } absolute right-[10px] top-[10px]`}
              >
                {!courseStatusEnd ? 'Sắp diễn ra' : 'Đã kết thúc'}
              </div>
            </div>
          </div>

          <div className="flex flex-col px-3">
            <div className="border-neutral-grayLight line-clamp-2 flex h-[124px] flex-col items-start py-2">
              {isShowLecturer && (
                <Link
                  href={`${UrlLink.knowledge.author}/${detailLecture?.slug}`}
                >
                  <h3 className="text-PC-Title-Regular hover:text-primary-eastern line-clamp-2 cursor-pointer py-1 font-sans">
                    {detailLecture?.name || 'Giảng viên không tồn tại'}
                  </h3>
                </Link>
              )}

              <h3 className="text-PC-Title-Large line-clamp-3 font-sans">
                {data?.name}
              </h3>
            </div>
            <div className="flex flex-row justify-between py-3">
              <div className="text-PC-Caption-Regular text-neutral-grayDark flex flex-col items-start justify-center gap-1 lg:flex-row lg:items-center">
                <span>
                  {data?.start_date &&
                    format(parseISO(data?.start_date), 'HH:mm dd/MM/yyyy')}
                </span>
              </div>
              <div
                className={`text-PC-Button-Small rounded-[4px] duration-150 ${
                  courseStatusEnd
                    ? 'bg-neutral-grayLight text-neutral-grayDark'
                    : 'bg-primary-easternDark text-white'
                }  w-fit  cursor-pointer px-[20px] py-[12px] hover:scale-105`}
                onClick={() => {
                  setShowModal(true);
                  router.push(
                    {
                      pathname: router.pathname,
                      query: { ...router.query },
                    },
                    `${UrlLink.knowledge.course}/${data?.slug}`,
                    {
                      shallow: true,
                    },
                  );
                }}
              >
                {courseStatusEnd ? 'Xem chi tiết' : 'Đăng ký'}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseCard;
