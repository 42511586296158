import { LeftArrowIcon1, RightArrowIcon1 } from '@/assets/icons';
import { UrlLink } from '@/configs/urlLink';
import Link from 'next/link';
import { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import CourseCard from '../Course/CourseCard';
import DetailCourseModal from '../Course/DetailCourseModal';
import { useGetAllLectureQuery } from '@/generated/graphql';

const CourseHomepageCard = () => {
  const [showModal, setShowModal] = useState(false);
  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();

  const settingsMobile: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: true,
  };

  const { data: listAllLectures, loading: isloadingLectures } =
    useGetAllLectureQuery({
      variables: {
        args: {
          limit_value: 9,
          offset_value: 0,
        },
      },
    });

  const listAllLecturersLength =
    listAllLectures?.get_list_lecture_by_date?.length;
  return (
    <div className="w-full bg-[#F8F8F8]">
      <div
        className={`container  bg-[#F8F8F8] ${
          listAllLectures?.get_list_lecture_by_date?.length ? 'pt-12' : 'hidden'
        }`}
      >
        <Link
          className="border-neutral-grayLight hover:text-primary-eastern flex items-center gap-5 border-b-[0.5px] pb-2 lg:border-b-[0px]"
          href={UrlLink.knowledge.course}
        >
          <h2 className="font-header px-2 text-2xl font-semibold">
            Khoá học đầu tư
          </h2>
        </Link>
        <div className="slider-container relative py-3">
          {listAllLecturersLength ? (
            <div>
              <div className="">
                <Slider
                  {...{
                    ...settingsMobile,
                    infinite: listAllLecturersLength < 4 ? false : true,
                  }}
                  ref={customSlider}
                >
                  {listAllLectures?.get_list_lecture_by_date?.map(
                    (item, index) => {
                      return (
                        <div key={index} className="!w-[284px] px-2">
                          <div
                            className={`bg-gray-variants-1 md:bg-gray-variants-2 
                                cursor-pointer rounded-xl `}
                          >
                            <CourseCard
                              key={index}
                              data={item}
                              isLoading={isloadingLectures}
                              setShowModal={setShowModal}
                              isShowLecturer
                            />
                          </div>
                        </div>
                      );
                    },
                  )}
                </Slider>
                {/* <div className="hidden md:block">
                <div
                  className={`left-[-50px] top-1/2  -translate-y-1/2 ${
                    Number(listAllLecturersLength) > 5 ? 'block' : 'hidden'
                  }`}
                >
                  <button onClick={goToPrev} className="cursor-pointer">
                    <LeftArrowIcon1 />
                  </button>
                </div>
                <div
                  className={`right-[-50px] top-1/2  -translate-y-1/2 ${
                    Number(listAllLecturersLength ?? 0) > 5 ? 'block' : 'hidden'
                  }`}
                >
                  <button onClick={goToNext}>
                    <RightArrowIcon1 className="cursor-point" />
                  </button>
                </div>
              </div> */}

                <div className="hidden lg:block">
                  <div
                    className={`left-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                      Number(listAllLecturersLength ?? 0) > 5
                        ? 'block'
                        : 'hidden'
                    }`}
                  >
                    <button onClick={goToPrev} className="cursor-pointer">
                      <LeftArrowIcon1 />
                    </button>
                  </div>
                  <div
                    className={`right-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                      Number(listAllLecturersLength ?? 0) > 5
                        ? 'block'
                        : 'hidden'
                    }`}
                  >
                    <button onClick={goToNext}>
                      <RightArrowIcon1 className="cursor-point" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {showModal && <DetailCourseModal setShowModal={setShowModal} />}
      </div>
    </div>
  );
};

export default CourseHomepageCard;
